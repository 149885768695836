import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { Tabs, Tab, Modal } from "react-bootstrap";
import { useSelector } from 'react-redux';
import user from "../assets/images/imgs/img1.png";
import leftArrow from "../assets/images/icons/left-arrow.svg";
import bg1 from "../assets/images/bg/bg-img.png";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import Avatar from "react-avatar";
import { ReactComponent as VerifyIcon } from "../assets/images/icons/verifyIcon.svg";
import defaultImg from '../assets/images/bg/bg-img.png';
import { t } from "i18next";

const UnlockMessageDetails = ({
  show,
  handleClose,
  paidPackageDetail,
  s3bucketurl,
  packageUser,
  setUnlockPopupDetails
}) => {
  const username = useSelector((state) => state.userDataReducer.username);
  const navigate = useNavigate();

  if (!(paidPackageDetail?.audio_data &&
    paidPackageDetail?.image_data &&
    paidPackageDetail?.video_data)) {
    // setUnlockPopupDetails(false)
    return null
  }

  return (
    <Modal show={show} centered className="unlock-popup">
      <Modal.Body>
        <div className="heading-text">
          <img src={leftArrow} alt="arrow" onClick={handleClose} />
          <h6>{paidPackageDetail.media_package_name}</h6>
        </div>
        <div className="unlock-message-details">
          <div className="user-detai-section">
            <div className="left-section">
              <Avatar
                className="m-auto"
                round={true}
                src={''}
                alt="profile"
                size="45"
                name={
                  paidPackageDetail?.user?.first_name +
                  ' ' +
                  paidPackageDetail?.user?.last_name
                }
                maxInitials={2}
              />
              <div className="user-name">
                <h5 className="post-user-name justify-content-start mb-0">
                  {paidPackageDetail?.user?.first_name + " " + paidPackageDetail?.user?.last_name}
                  {paidPackageDetail?.user?.user_verified && (
                    <VerifyIcon className="mx-1" width="16" height="16" />
                  )}
                </h5>
                <h6>@{paidPackageDetail?.user?.username}</h6>
                {/* <p>@ajaytest</p> */}
              </div>
            </div>
            {paidPackageDetail?.user?.username !== username && <Button
              onClick={() => {
                navigate(`/user-profile/${paidPackageDetail?.user_id}`);
              }}
            >
              View Profile
            </Button>}
          </div>
          <Tabs
            defaultActiveKey="All"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="All" title="All">
              <All
                paidPackageDetail={paidPackageDetail}
                s3bucketurl={s3bucketurl}
              />
            </Tab>
            <Tab eventKey="Image" title="Image">
              <ImageSection
                paidPackageDetail={paidPackageDetail}
                s3bucketurl={s3bucketurl}
              />
            </Tab>
            <Tab eventKey="Video" title="Video">
              <VideoSection
                paidPackageDetail={paidPackageDetail}
                s3bucketurl={s3bucketurl}
              />
            </Tab>
            <Tab eventKey="Audio" title="Audio">
              <AudioSection
                paidPackageDetail={paidPackageDetail}
                s3bucketurl={s3bucketurl}
              />
            </Tab>
          </Tabs>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default UnlockMessageDetails;

const All = ({ paidPackageDetail, s3bucketurl }) => {
  let isdata = paidPackageDetail?.image_data?.length || paidPackageDetail?.audio_data?.length || paidPackageDetail?.video_data?.length
  return (
    <div className={`video-section-list ${isdata ? 'd-block' : 'd-flex'}`}>
      {isdata ? <>
        {paidPackageDetail?.image_data && (
          <div className="video-box w-100">
            <div className="row m-0">
              {paidPackageDetail?.image_data?.map((data, index) => (
                <div className="col-md-6 mb-3" key={index}>
                  <img
                    src={`${s3bucketurl}/${data.media_content_file}`}
                    alt={`bg${index + 1}`}
                    className="img-fluid"
                  />
                </div>
              ))}
            </div>
          </div>
        )}

        {paidPackageDetail?.audio_data && (
          <div className="video-box w-100">
            <div className="row m-0">
              {paidPackageDetail.audio_data.map((data, index) => (
                <div className="col-md-6 d-flex align-items-center mb-3" key={index} style={{ height: "200px" }}>
                  <img
                    src={data?.media_content_thumb ? s3bucketurl + '/' + data?.media_content_thumb : defaultImg}
                    alt="leftArrow"
                  />
                  <audio
                    src={s3bucketurl + "/" + data?.media_content_file}
                    controls
                    className="mb-2"
                    style={{ position: 'absolute', width: '80%', marginLeft: '5%' }}
                  />
                </div>
              ))}
            </div>
          </div>
        )}

        {paidPackageDetail?.video_data && (
          <div className="video-box w-100">
            <div className="row m-0">
              {paidPackageDetail.video_data.map((data, index) => (
                <div className="col-md-6" key={index}>
                  <video controls width="100%" height={'200px'} className="mb-2" poster={s3bucketurl + "/" + data.media_content_thumb} style={{ objectFit: 'cover' }}>
                    <source
                      src={s3bucketurl + "/" + data.media_content_file}
                      type="video/mp4"
                    />
                    Sorry, your browser doesn't support embedded videos.
                  </video>
                </div>
              ))}
            </div>
          </div>
        )}
      </> : <h4 className="w-100 text-center border-0"></h4>}
    </div>
  );
};
const VideoSection = ({ paidPackageDetail, s3bucketurl }) => {
  return (
    <div className={`video-section-list ${paidPackageDetail?.video_data?.length ? 'd-block' : 'd-flex'}`}>
      {paidPackageDetail?.video_data?.length ? (
        <div className="video-box w-100">
          <div className="row m-0">
            {paidPackageDetail.video_data.map((data, index) => (
              <div className="col-md-6 package-video" key={index}>
                <div className="video-container">
                  <video
                    controls
                    width="100%"
                    height="100%"
                    className="mb-2"
                    poster={`${s3bucketurl}/${data.media_content_thumb}`}
                    style={{ objectFit: 'cover' }}
                  >
                    <source
                      src={`${s3bucketurl}/${data.media_content_file}`}
                      type="video/mp4"
                    />
                    Sorry, your browser doesn't support embedded videos.
                  </video>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : <h4 className="w-100 text-center border-0"></h4>}
    </div>
  );
};
const ImageSection = ({ paidPackageDetail, s3bucketurl }) => {
  return (
    <div className={`video-section-list ${paidPackageDetail?.image_data?.length ? 'd-block' : 'd-flex'}`}>
      {paidPackageDetail?.image_data?.length ? (
        <div className="video-box w-100">
          <div className="row m-0">
            {paidPackageDetail?.image_data?.map((data, index) => (
              <div className="col-md-6 mb-3" key={index}>
                <img src={s3bucketurl + "/" + data.media_content_file} alt={`bg${index + 1}`} className="img-fluid" />
              </div>
            ))}
          </div>
        </div>
      ) : <h4 className="w-100 text-center border-0"></h4>}
    </div>
  );
};
const AudioSection = ({ paidPackageDetail, s3bucketurl }) => {
  return (
    <div className={`video-section-list ${paidPackageDetail?.audio_data?.length ? 'd-block' : 'd-flex'}`}>
      {paidPackageDetail?.audio_data?.length ? (
        <div className="video-box w-100">
          <div className="row m-0">
            {paidPackageDetail.audio_data.map((data, index) => (
              <div className="col-md-6 d-flex align-items-center mb-3" key={index} style={{ height: "200px" }}>
                <img
                  src={data?.media_content_thumb ? s3bucketurl + '/' + data?.media_content_thumb : defaultImg}
                  alt="leftArrow"
                />
                <audio
                  src={s3bucketurl + "/" + data?.media_content_file}
                  controls
                  className="mb-2"
                  style={{ position: 'absolute', width: '80%', marginLeft: '5%' }}
                />
              </div>
            ))}
          </div>
        </div>
      ) : <h4 className="w-100 text-center border-0">{t('message.')}</h4>}
    </div>
  );
};
