import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Profile from '../../assets/images/imgs/user-round.svg';
import { S3_BASE_URL } from '../../service/aws/config';
import { toast } from 'react-toastify';
import { METHOD, apiCall } from '../../service/baseApiCall';
import { POST_DONATE } from '../../redux/ApiEndPoints';
import coins from '../../assets/images/imgs/coins.svg';
import Loader from '../Loader';
import { useSelector } from 'react-redux';
import { Store } from '../../redux/Actions';
import Avatar from 'react-avatar';
import { t } from 'i18next';

export default function DonateModel({
  showDonate,
  setDonate,
  data,
  postId,
}: any) {
  const [donateAmount, setDonateAmount] = useState('100');
  const [emptyErr, setEmptyErr] = useState(false);
  const [numberErr, setNumberErr] = useState(false);
  const [balanceError, setbalanceError] = useState(false);
  const [donateText, setDonateText] = useState('');
  const [loadingDonate, setLoadingDonate] = useState(false);
  const userDetail: any = useSelector(
    (state: Store) => state.userDataReducer.userDetail
  );
  useEffect(() => {
    return () => { setEmptyErr(false); setNumberErr(false); setbalanceError(false); setDonateAmount('100'); setDonateText('')}
  }, [showDonate])
  const donateToCreator = async () => {
    if (!donateAmount) {
      setEmptyErr(true);
    } else if (isNaN(Number(donateAmount)) || Number(donateAmount) == 0) {
      setNumberErr(true);
    } else if (userDetail?.wallet_balance < donateAmount) {
      setbalanceError(true);
    } else {
      setLoadingDonate(true);
      try {
        await apiCall(
          POST_DONATE,
          { amount: Number(donateAmount), post_id: postId },
          () => {
            setDonate(false);
            toast.success('Donated Successfullly');
            setLoadingDonate(false);
            setDonateText('');
            setDonateAmount('100');
          },
          () => {
            setDonate(false);
            toast.error('Donation Failed');
            setLoadingDonate(false);
          },
          METHOD.POST
        );
      } catch (error) {
        toast.error('Something went wrong');
        setLoadingDonate(false);
      }
    }
  };
  const handleUserInput = (e: any) => {
    setDonateAmount(e.target.value);
    setDonateText(e.target.value);
    if (e.target.value === '') {
      setDonateAmount('100');
    } else if (e.target.value) {
      setDonateText(e.target.value.replace(/[^0-9]/g, ''));
      setDonateAmount(e.target.value.replace(/[^0-9]/g, ''));
    }
  };
  return (
    <Modal
      show={showDonate}
      onHide={() => setDonate(false)}
      centered
      className="custom-modal donate-modal"
    >
      <Modal.Body>
        <div className="donate-content">
          <div className="user-details">
            <Avatar
              className="avtar mb-3"
              round={true}
              src={S3_BASE_URL + data?.profile_photo}
              alt="profile"
              size="95"
              name={data?.first_name + ' ' + data?.last_name}
              maxInitials={2}
            />
            <h6>
              {data?.first_name} {data?.last_name}
            </h6>
          </div>
          <div className="tip-section">
            {/* <h6>TIP</h6> */}
            <p>@{data?.username}</p>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#e2e2e2',
                width: 'max-content',
                height: 'auto',
                borderRadius: '28px',
                maxWidth: 'fix-content',
                margin: '20px auto 30px',
                padding: '5px 20px',
              }}
            >
              <img src={coins} alt="coin" style={{ width: '60px' }} />
              <h1 className="mb-2"> {donateAmount}</h1>
            </div>

            <div className="choose-option-list">
              {['100', '200', '500', '1000'].map((amt) => (
                <button
                  className={`select-option ${amt === donateAmount ? 'active' : ''
                    }`}
                  key={amt}
                  onClick={() => setDonateAmount(amt)}
                >
                  {amt}
                </button>
              ))}
            </div>
            <input
              type="text"
              placeholder={`${t('home.custom amount')}`}
              value={donateText}
              // onChange={(e) =>
              //   setDonateAmount(e.target.value.replace(/[^0-9]/g, ""))
              // }
              onChange={handleUserInput}
              onClick={() => {
                setEmptyErr(false);
                setNumberErr(false);
                setbalanceError(false);
              }}
            />

            {emptyErr && <div className="text-danger">Amount is required</div>}
            {numberErr && (
              <div className="text-danger">Amount must be greater than 0</div>
            )}
            {balanceError && (
              <div className="text-danger">You have insufficient balance</div>
            )}
            <Button
              disabled={loadingDonate}
              onClick={donateToCreator}
              style={{ fontWeight: '600', fontSize: '20px' }}
            >
            {t('home.tip')}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
