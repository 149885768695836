import React, { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import { Button, Modal } from 'react-bootstrap';
import { followUser, getFollowerList, unfollowUser } from '../../redux/action';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux';
import { Store, UserData } from '../../redux/Actions';
import { useSelector } from 'react-redux';
import { S3_BASE_URL } from '../../service/aws/config';
import leftArrow from '../../assets/images/icons/left-arrow.svg';
import Loader from '../Loader';
import { ReactComponent as VerifyIcon } from '../../assets/images/icons/verifyIcon.svg';
import { useNavigate } from 'react-router-dom';
import { getToken } from '../../utils/utility';
import { t } from 'i18next';

const Followers = ({
  isOpenFollowerModal,
  setIsOpenFollowerModal,
  userId,
}: any) => {
  const [page, setPage] = useState(1);
  const [loading1, setLoading1] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { followerList, loading }: UserData = useSelector(
    (state: Store) => state.userDataReducer
  );
  const [userProfileId] = useState<any>(getToken('userId'));
  const userFollowerData: { userId: String; page: number } = { userId, page };

  useEffect(() => {
    dispatch(getFollowerList(userFollowerData));
  }, []);
  useEffect(() => {
    dispatch(getFollowerList(userFollowerData));
  }, [loading1]);

  const handleFollowUser = async (id: any) => {
    await dispatch(followUser(id));
    await dispatch(getFollowerList(userFollowerData));
    setLoading1(!loading1);
  };
  const handleUnfollowUser = async (id: any) => {
    await dispatch(unfollowUser(id));
    await dispatch(getFollowerList(userFollowerData));
    setLoading1(!loading1);
  };

  return (
    <Modal
      show={isOpenFollowerModal}
      onHide={() => setIsOpenFollowerModal(false)}
      className="view-follower-modal"
      centered
    >
      {/* {loading && <Loader />} */}
      {/* <Modal.Body> */}
      <button
        type="button"
        className="btn-close d-md-none"
        data-bs-dismiss="modal"
      ></button>
      <div className="center-box">
        <div className="box-container notification-container">
          <div className="d-flex following-page-title-div">
            {/* <img
              src={leftArrow}
              alt="leftArrow"
              style={{ marginLeft: "12px" }}
            /> */}
            <div></div>
            <h5 className="text-center following-page-title">{t('sidebar.followers')}</h5>
            <button
              style={{ marginRight: '12px' }}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              onClick={() => setIsOpenFollowerModal(false)}
            ></button>
          </div>
          <div
            className={
              loading
                ? 'follower-following-height-scroll-following-modal'
                : 'follower-following-height-scroll'
            }
          >
            {loading ? (
              <div className="spinner"></div>
            ) : (
              <div>
                {followerList?.length > 0
                  ? followerList?.map((elem) => {
                      return (
                        <div className="blacklist-link d-flex align-items-center justify-content-between px-0 m-3">
                          <div
                            className="blacklist-user d-flex align-items-center"
                            onClick={() => {
                              navigate(
                                `/user-profile/${elem.follower_details.id}`
                              );
                              setIsOpenFollowerModal(false);
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            <Avatar
                              className="avtar blacklist-setting-image"
                              round={true}
                              src={
                                S3_BASE_URL +
                                elem?.follower_details?.profile_photo
                              }
                              alt="profile"
                              size="30"
                              name={
                                elem.follower_details?.first_name +
                                ' ' +
                                elem.follower_details?.last_name
                              }
                              maxInitials={2}
                              // style={{ position: "relative" }}
                              style={{ objectFit: 'cover !important' }}
                            />
                            <div className="col">
                              <p className="text-msg user-comment">
                                {elem.follower_details?.first_name +
                                  ' ' +
                                  elem.follower_details?.last_name}
                                {elem.follower_details?.user_verified && (
                                  <VerifyIcon
                                    className="mx-1"
                                    height={'14'}
                                    width={'14'}
                                  />
                                )}
                              </p>
                            </div>
                          </div>

                          {elem.user_id == userProfileId ? (
                            ''
                          ) : (
                            <div className="blacklist-button mb-2">
                              {elem.follower_details?.is_following ? (
                                <Button
                                  className="btn btn-unblock"
                                  onClick={() => {
                                    handleUnfollowUser(elem.user_id);
                                  }}
                                  style={{ fontSize: '11px' }}
                                >
                                  {t('sidebar.following')}
                                </Button>
                              ) : (
                                <Button
                                  variant="outline-secondary"
                                  onClick={() => {
                                    handleFollowUser(elem.user_id);
                                  }}
                                  style={{ width: '81px', fontSize: '11px' }}
                                >
                                  {t('sidebar.follow')}
                                </Button>
                              )}
                            </div>
                          )}
                          {/* <div className="blacklist-button mb-4">
                      {elem.follower_details?.is_following ? (
                        <Button
                          className="btn btn-unblock"
                          onClick={() => {
                            handleUnfollowUser(elem.user_id);
                          }}
                        >
                          Followers
                        </Button>
                      ) : (
                        <Button
                          variant="outline-secondary"
                          onClick={() => {
                            handleFollowUser(elem.user_id);
                          }}
                        >
                          Follow
                        </Button>
                      )}
                    </div> */}
                        </div>
                      );
                    })
                  : !loading && (
                      <div style={{ textAlign: 'center' }}>
                        <h4 style={{ marginTop: '30px' }}>No Result Found !</h4>
                      </div>
                    )}
              </div>
            )}
          </div>
        </div>
      </div>
      {/* </Modal.Body> */}
    </Modal>
  );
};

export default Followers;
