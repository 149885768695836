import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Modal, OverlayTrigger, Popover } from 'react-bootstrap';
import { Story } from '../../pages/Home';
import { FiChevronRight } from 'react-icons/fi';
import { RiMore2Line } from 'react-icons/ri';
import moment from 'moment';
import { log } from 'console';
import { useTranslation } from 'react-i18next';
import { DELETE_STORIES_URL } from '../../redux/ApiEndPoints';
import { METHOD, apiCall } from '../../service/baseApiCall';
import { toast } from 'react-toastify';
import { getToken } from '../../utils/utility';
import { AppDispatch } from '../../redux';
import { useDispatch } from 'react-redux';

interface Props {
  story: Story | undefined;
  viewOwnStory?: boolean;
  onNextStory?: () => void;
  onPrevStory?: () => void;
  onClose: () => void;
  show?: boolean;
  storiesList?: any;
  selectedStory?: number;
}

let timer: NodeJS.Timeout;

const ViewStoryModal: React.FC<Props> = ({
  story,
  viewOwnStory,
  selectedStory,
  storiesList,
  onNextStory,
  onPrevStory,
  onClose,
  show,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const currentUserId = getToken('userId');
  const [imgIndex, setImageIndex] = useState(0);
  const [imgIndexPrev, setImageIndexPrev] = useState(0);
  const [width, setWidth] = useState(0);
  const [newStory, setNewStory] = useState(-1);
  const [showStory, setShowStory] = useState<any>({});
  const [deleteStory, setDeleteStory] = useState<boolean>(false);
  useEffect(() => {
    if (selectedStory !== undefined) {
      setNewStory(selectedStory);
    }
  }, [selectedStory]);

  useEffect(() => {
    if (
      storiesList &&
      storiesList?.stories &&
      storiesList?.stories?.length &&
      newStory !== undefined
    ) {
      setShowStory(storiesList?.stories[newStory]);
    }
  }, [newStory]);

  const startTimer = () => {
    if (!deleteStory) {
      clearInterval(timer);

      timer = setInterval(() => {
        setWidth((prev) => prev + 1.5);
      }, 100);
    }
  };

  if (show) {
    startTimer();
  } else {
    clearInterval(timer);
    if (width) {
      setWidth(0);
    }
  }
  useEffect(() => {
    return () => clearInterval(timer);
  }, []);
  useEffect(() => {
    if (width >= 100 && imgIndex + 1 <= (showStory?.story_image?.length || 0)) {
      // setImageIndex((prev) => prev + 1);
      setImageIndex((prevState) =>
        prevState + 1 <= (showStory?.story_image?.length || 0)
          ? prevState + 1
          : (showStory?.story_image?.length || 0) - 1
      );
      // clearInterval(timer);
      setWidth(0);
      // startTimer();
    } else if (
      imgIndex === showStory?.story_image?.length &&
      newStory < storiesList?.stories?.length - 1
    ) {
      setNewStory(newStory + 1);
      setImageIndex(0);
    } else if (
      imgIndex === showStory?.story_image?.length &&
      newStory === storiesList?.stories?.length - 1
    ) {
      handleModalClose();
      setImageIndex(0);
      setNewStory(0);
    }
  }, [width]);
  const handleModalClose = () => {
    onClose();
    setImageIndex(0);
    setNewStory(0);
  };
  const handleIndex = () => {
    if (newStory == storiesList?.stories?.length - 1) {
      handleModalClose();
      onClose();
    } else {
      setNewStory(newStory + 1);
      setImageIndex(0);
    }
  };
  const handleDeleteStory = async (id: any) => {
    try {
      await apiCall(
        DELETE_STORIES_URL,
        { story_id: id },
        (data: any) => {
          toast.success('Story Delete Success');
          handleModalClose();
        },
        () => {
          toast.success('Story Delete Failed');
        },
        METHOD.POST
      );
    } catch (error) {
      toast.error('Something went wrong');
    }
  };
  return (
    <Modal
      backdrop
      onBackdropClick={onClose}
      show={show}
      centered
      onHide={handleModalClose}
      size="sm"
      className="view-story-modal"
    >
      <div className="view-story-modal-body">
        <div className="story-modal-header">
          {showStory?.user?.profile_photo ? (
            <img
              className="author-img"
              src={`${process.env.REACT_APP_S3_BASE_URL}/${showStory?.user?.profile_photo}`}
              alt=""
            />
          ) : (
            <p className="author-img">
              {showStory?.user?.first_name?.[0]?.toUpperCase()}
              {showStory?.user?.last_name?.[0]?.toUpperCase()}
            </p>
          )}
          <div className="story-author">
            <div className="author">
              <p>
                {showStory?.user?.first_name} {showStory?.user?.last_name}
              </p>
              <span>
                {moment(
                  showStory?.story_image?.[imgIndex]?.created_at
                ).fromNow()}
              </span>
            </div>
            {showStory?.user?.id == currentUserId && (
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                // overlay={popover}
                rootClose={true}
                overlay={
                  <Popover
                    className="story-delete-pop-over"
                    style={{ zIndex: '99999999999999' }}
                  >
                    <p
                      className="story-delete-pop-over-text"
                      onClick={() =>
                        handleDeleteStory(
                          showStory?.story_image?.[imgIndex]?.id
                        )
                      }
                    >
                      {t('home.delete')}
                    </p>
                  </Popover>
                }
              >
                <button className="more-btn-icon">
                  <RiMore2Line />
                </button>
              </OverlayTrigger>
            )}
          </div>
        </div>
        <div
          className="story-modal-slider row p-0 position-relative "
          style={{ backgroundColor: '#9bbccd' }}
          // style={{
          //   backgroundImage: `url(${process.env.REACT_APP_S3_BASE_URL}/${showStory?.story_image?.[imgIndex]?.story_image})`,
          // }}
        >
          <div
            className="blurbackground"
            style={{
              backgroundImage: `url(${process.env.REACT_APP_S3_BASE_URL}/${showStory?.story_image?.[imgIndex]?.story_image})`,
            }}
          ></div>

          {imgIndex > 0 ? (
            <div
              className="col-md-6 prev-story m-0"
              onClick={() => {
                setImageIndex((prevState) =>
                  imgIndex < (showStory?.story_image?.length || 0)
                    ? prevState - 1
                    : (showStory?.story_image?.length || 0) - 1
                );
                setWidth(0);
              }}
            ></div>
          ) : (
            ''
          )}

          <div
            onClick={() => {
              setImageIndex((prevState) =>
                prevState + 1 < (showStory?.story_image?.length || 0)
                  ? prevState + 1
                  : (showStory?.story_image?.length || 0) - 1
              );

              setWidth(0);
            }}
            className="col-md-6 nxt-story m-0"
          ></div>

          <div
            className="story-img p-0"
            data-image={`${process.env.REACT_APP_S3_BASE_URL}/${showStory?.story_image?.[imgIndex]?.story_image}`}
          >
            <img
              src={`${process.env.REACT_APP_S3_BASE_URL}/${showStory?.story_image?.[imgIndex]?.story_image}`}
              alt=""
            />
          </div>
          <div className="story-progress-bars">
            {showStory?.story_image?.map((i: any, idx: any) => (
              <div className="img-progress-bar" key={JSON.stringify(i)}>
                <div
                  className="img-internal-progress-bar"
                  style={{
                    width:
                      idx === imgIndex
                        ? `${width}%`
                        : idx < imgIndex
                        ? '100%'
                        : '0%',
                  }}
                />
              </div>
            ))}
          </div>
        </div>

        {showStory &&
          showStory.story_image &&
          storiesList?.stories?.length > 1 && (
            <button className="next-story-btn" onClick={handleIndex}>
              <FiChevronRight />
            </button>
          )}
      </div>
    </Modal>
  );
};

export default ViewStoryModal;
