import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
// import User1 from "../assets/images/imgs/user1.svg"
// import MoreIcon from "../assets/images/icons/ico_more.svg"
// import PostImg1 from "../assets/images/imgs/postimg1.svg"
// import LikeIcon from "../assets/images/icons/ico_heart.svg"
// import CommentIcon from "../assets/images/icons/ico_comment.svg"
// import PlayIcon from "../assets/images/icons/ico_play.svg"
// import LockIcon from "../assets/images/icons/ico_lock.svg"
import { useSelector } from 'react-redux';
import { Store } from '../../redux/Actions';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux';
import {
  deletePostSuccess,
  followUser,
  getFollowerList,
  removeMediaFromPackage,
  unfollowUser,
  userDashboardFeed,
  userFeed,
} from '../../redux/action';
import {
  addToFavourite,
  removeFromFavourite,
} from '../../redux/action/postActions';
import { RWebShare } from 'react-web-share';
import { METHOD, apiCall } from '../../service/baseApiCall';
import {
  DELETE_POST,
  HIDE_POST,
  REPORT_POST,
  UNLOCK_POST,
} from '../../redux/ApiEndPoints';
import { USER_FEEDS } from '../../redux/constants/action-types';
import { toast } from 'react-toastify';
import shareIcon from '../../assets/images/icons/share-icon.svg';
import { FiLink, FiX } from 'react-icons/fi';
import Loader from '../Loader';
import { useTranslation } from 'react-i18next';

export default function ShareModel({
  showShareModel,
  setShowPostComment,
  postId,
  page,
  fromPage,
  isFavourite,
  currentUser,
  userId,
  userFeedListData,
  userIsFollowing,
  setdeleteFeedDependenncy,
  deleteFeedDependenncy,
  shareLinkInfo,
}: any) {
  const [disabledDependency, setDisabledDependency] = useState(false);
  const user: any = useSelector(
    (state: Store) => state.userDataReducer.userDetail
  );
  const loading: any = useSelector(
    (state: Store) => state.userDataReducer.loading
  );
  const ref: any = useRef(null);
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      setShowPostComment(false);
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [setShowPostComment]);

  const reportPost = async () => {
    try {
      await apiCall(
        REPORT_POST,
        { post_id: postId },
        (data: any) => {
          toast.success('Post reported');
          dispatch(userDashboardFeed(page));
          setdeleteFeedDependenncy(!deleteFeedDependenncy);
        },
        () => {
          toast.success('Reporting Failed');
        },
        METHOD.POST
      );
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  const deletePost = async () => {
    try {
      await apiCall(
        DELETE_POST,
        { post_id: postId },
        (data: any) => {
          if (data?.success) {
            dispatch(deletePostSuccess(postId));
            toast.success(t('home.post deleted'))
            dispatch(userDashboardFeed(page));
            setdeleteFeedDependenncy(!deleteFeedDependenncy);
          }
        },
        () => {
          toast.success('Post Delete Failed');
        },
        METHOD.POST
      );
    } catch (error) {
      toast.error('Something went wrong');
    }
  };
  const hidePost = async () => {
    try {
      await apiCall(
        HIDE_POST,
        { post_id: postId, to_user_id: user?.id },
        (data: any) => {
          dispatch({
            type: USER_FEEDS.POST_HIDE,
            payload: postId,
          });
          dispatch({
            type: USER_FEEDS.POST_HIDE_FAVOURITE,
            payload: postId,
          });
          dispatch(removeFromFavourite(postId));
          dispatch(userDashboardFeed(page));
        },
        () => { },
        METHOD.POST
      );
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  const handleUserFollow = async () => {
    await dispatch(followUser(userId));
    // if (fromPage === "search-profile") {
    //   await dispatch(userFeed(page, userId));
    // } else {
    //   await dispatch(userDashboardFeed(page));
    // }
  };
  const handleUserUnfollow = async () => {
    await dispatch(unfollowUser(userId));
    if (fromPage === 'search-profile') {
      await dispatch(userFeed(page, userId));
    } else {
      // await dispatch(userDashboardFeed(page));
    }
  };
  return (
    <Modal
      show={showShareModel}
      onHide={() => setShowPostComment(false)}
      className="action-modal-popup"
      centered
      style={{ height: 'auto !important' }}
    >
      <Modal.Body>
        <div className="list-group">
          {userId == currentUser ? (
            <div
              className="list-group-item"
              onClick={() => {
                deletePost();
              }}
            >
              <button className="delete-button-unset">
                {t('home.delete')}
              </button>
            </div>
          ) : (
            <div className="list-group-item" onClick={reportPost}>
              {t('home.report')}
            </div>
          )}
          {isFavourite === true ? (
            <div
              className="list-group-item list-report"
              onClick={() => {
                dispatch(removeFromFavourite(postId));
              }}
            >
              {t('home.remove to favorite')}
            </div>
          ) : (
            <div
              className="list-group-item"
              onClick={() => {
                dispatch(addToFavourite(postId));
              }}
            >
              {t('home.add to favorite')}
            </div>
          )}
          {userId !== currentUser && (
            <div className="list-group-item" onClick={hidePost}>
              {t('home.hide')}
            </div>
          )}
          {userId != currentUser &&
            (userIsFollowing ? (
              <div
                className="list-group-item"
                onClick={() => handleUserUnfollow()}
              >
                {t('home.unfollow')}
              </div>
            ) : (
              <div
                className="list-group-item"
                onClick={() => handleUserFollow()}
              >
                {t('home.follow')}
              </div>
            ))}

          {/* <div className="list-group-item" onClick={hidePost}>
            Hide
          </div>
          <div
            className="list-group-item"
            onClick={() => {
              dispatch(unfollowUser(userId));
            }}
          >
            Unfollow
          </div>
          <div className="list-group-item list-report" onClick={reportPost}>
            Report
          </div> */}
        </div>
      </Modal.Body>
    </Modal>
  );
}
